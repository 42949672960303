<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Claimant first name">
          <v-text-field
            autocomplete="new-password"
            @input="
              handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FIRST_NAME, arguments[0])
            "
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FIRST_NAME]"
            disabled
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Claimant last name">
          <v-text-field
            autocomplete="new-password"
            @input="
              handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_LAST_NAME, arguments[0])
            "
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_LAST_NAME]"
            disabled
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Injured person first name">
          <v-text-field
            autocomplete="new-password"
            @input="
              handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FIRST_NAME, arguments[0])
            "
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FIRST_NAME]"
            disabled
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-end">
        <FormSection title="Injured person last name">
          <v-text-field
            autocomplete="new-password"
            @input="
              handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_LAST_NAME, arguments[0])
            "
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_LAST_NAME]"
            disabled
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Body part">
          <v-select
            @input="
              handleFieldInput(
                CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED,
                arguments[0]
              )
            "
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]"
            :items="selectOptions.bodyParts"
            disabled
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-end">
        <FormSection title="Injury type">
          <v-select
            @input="
              handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_INJURY_TYPE, arguments[0])
            "
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_INJURY_TYPE]"
            :items="selectOptions.injuryTypes"
            disabled
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Date of injury">
          <v-text-field
            autocomplete="new-password"
            @input="handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_DATE, arguments[0])"
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_DATE]"
            disabled
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-end">
        <FormSection title="Time of injury">
          <v-text-field
            autocomplete="new-password"
            @input="handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_TIME, arguments[0])"
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_TIME]"
            disabled
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Location of injury">
          <v-text-field
            autocomplete="new-password"
            @input="handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_LOCATION, arguments[0])"
            :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_LOCATION]"
            disabled
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_REVIEW_FIELD_ALIASES.COACH_SIGNATURE, arguments[0])"
          :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.COACH_SIGNATURE]"
          :rules="['required']"
          label="Your name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          class="mt-2"
          @change="
            handleFieldInput(
              CLAIMANT_REVIEW_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL,
              arguments[0] || false
            )
          "
          :input-value="this[CLAIMANT_REVIEW_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL]"
          :value="this[CLAIMANT_REVIEW_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL]"
          :rules="['required']"
        >
          <template v-slot:label>
            <span>I read and agree with the</span>
            <PhLink @click="$emit('claimInfoDisclaimerClicked')" class="ml-1" clickOnly>
              Fraud Statements
            </PhLink>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <FormWaiverDisclaimer />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { bodyParts, injuryTypes } from '@/utils/options';
import { getClaimantInfoReviewFormProps } from '@/utils/helpers/app/claims';

const FormWaiverDisclaimer = () => import('@/components/typography/FormWaiverDisclaimer');
const FormSection = () => import('@/components/FormSection');
const PhLink = () => import('@/components/PhLink');

export default {
  name: 'ClaimantInfoReview',
  mixins: [syncForm],
  components: {
    FormSection,
    PhLink,
    FormWaiverDisclaimer,
  },
  props: {
    ...getClaimantInfoReviewFormProps(),
  },
  data() {
    return {
      selectOptions: {
        bodyParts,
        injuryTypes,
      },
    };
  },
};
</script>

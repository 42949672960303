<template>
  <h1 :style="`--text-transform: ${transform}`" class="page-subtitle">
    <slot>{{ content }}</slot>
  </h1>
</template>

<script>
export default {
  name: 'PageSubtitle',
  props: {
    content: String,
    transform: {
      type: String,
      default: 'uppercase',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-subtitle {
  color: $text-darkest;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: var(--text-transform);
}
</style>

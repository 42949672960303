<template>
  <v-form @submit.prevent="submit" ref="form" class="claim-review-form">
    <div class="claim-review-form__section-wrapper">
      <FormGroup title="Injured person information:" titleTransform="uppercase">
        <ClaimantInfoReview
          @claimInfoDisclaimerClicked="$emit('claimInfoDisclaimerClicked')"
          v-bind="claimantInfoReviewFormProps"
          @fieldInput="handleFormSectionFieldInput"
        />
      </FormGroup>
    </div>
    <div class="claim-review-form__footer-wrapper my-8">
      <v-row class="claim-review-form__footer">
        <v-col cols="12">
          <div class="d-flex flex-column flex-md-row justify-md-end">
            <v-btn :loading="loading" :disabled="buttonsDisabled" class="success" type="submit">
              Confirm incident
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="buttonsDisabled"
              @click="handleDeclineClick"
              class="error mt-4 mt-md-0 ml-md-5"
            >
              Decline incident
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { claimForm, syncForm, validateForm } from '@/mixins';
import ClaimantInfoReview from '@/components/forms/ClaimForm/partials/ClaimantInfoReview';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'ClaimReviewForm',
  mixins: [claimForm, syncForm, validateForm],
  components: {
    ClaimantInfoReview,
    FormGroup,
  },
  computed: {
    buttonsDisabled() {
      return (
        !this[this.CLAIMANT_REVIEW_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL] ||
        !this[this.CLAIMANT_REVIEW_FIELD_ALIASES.COACH_SIGNATURE]
      );
    },
  },
  methods: {
    handleDeclineClick() {
      this.$emit('decline');
    },
    handleFormSectionFieldInput({ field, value }) {
      this.handleFieldInput(field, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-review-form__section-wrapper {
  &:nth-child(1n + 2) {
    margin-top: 45px;
  }
}
</style>

<template>
  <div class="fnol-review-view fill-height">
    <Preloader :value="organizationPageParams.loading" fullscreen />
    <InputDialog
      @dialogSubmitted="rejectFnolHandler"
      v-model="rejectionDialogShown"
      title="Decline Claim"
    />
    <InputDialog
      :reasonRequired="false"
      @close="handleThankYouDialogClosed"
      v-model="thankYouDialogShown"
      buttonText="OK"
    >
      <template v-slot:content>
        <div v-html="thankYouDialogContent" class="fnol-review-view__modal-text-wrapper"></div>
      </template>
    </InputDialog>
    <v-container
      v-if="organizationPageParams.ready"
      class="fnol-review-view__container"
      fill-height
      align-start
      align-content-start
    >
      <v-row class="fnol-review-view__header" no-gutters>
        <v-col>
          <div class="fnol-review-view__header-logo-wrapper">
            <Logo :src="organizationPageParams.logoUrl" large />
          </div>
          <div v-if="pageTitle" class="fnol-review-view__header-title-wrapper">
            <PageSubtitle>{{ pageTitle }}</PageSubtitle>
          </div>
          <div
            v-if="organizationPageParams.complianceWarning"
            class="fnol-review-view__header-text-wrapper"
          >
            <p class="fnol-review-view__header-text my-0">
              {{ organizationPageParams.complianceWarning }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="fnol-review-view__body mt-4 mt-md-10" no-gutters>
        <v-col>
          <ClaimReviewForm
            @decline="toggleRejectionDialog(true)"
            @submit="confirmFnolHandler()"
            @claimInfoDisclaimerClicked="showClaimsInfoDisclaimer"
            v-bind.sync="form"
            :loading="formIsLoading"
          />
        </v-col>
      </v-row>
    </v-container>
    <ClaimsTermsDialog @agree="handleClaimsInfoAgree" v-model="claimsInfoDisclaimerDialogShown" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { organizationPageParams } from '@/mixins';
import {
  ANALYTIC_EVENTS,
  INFO_SCREEN_TYPES,
  INFO_SCREEN_TYPES_PARAMS,
  THANK_YOU_TYPES,
  THANK_YOU_TYPES_PARAMS,
} from '@/utils/constants';
import {
  getClaimantInfoReviewFormProps,
  getClaimFormFromApiPayload,
} from '@/utils/helpers/app/claims';
import ClaimReviewForm from '@/components/forms/ClaimForm/ClaimReviewForm';
import Preloader from '@/components/Preloader';
import Logo from '@/components/Logo';
import PageSubtitle from '@/components/typography/PageSubtitle';

const InputDialog = () => import('@/components/modals/InputDialog');
const ClaimsTermsDialog = () => import('@/components/modals/ClaimsTermsDialog');

const CLAIMANT_REVIEW_FIELD_ALIASES = getClaimantInfoReviewFormProps().CLAIMANT_REVIEW_FIELD_ALIASES.default();

export default {
  name: 'FNOLReview',
  mixins: [organizationPageParams],
  components: {
    ClaimReviewForm,
    InputDialog,
    Preloader,
    Logo,
    PageSubtitle,
    ClaimsTermsDialog,
  },
  data() {
    return {
      formIsLoading: false,
      fnolRejected: false,
      rejectionDialogShown: false,
      thankYouDialogShown: false,
      claimsInfoDisclaimerDialogShown: false,
      form: {
        // claimant info
        [CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: '',
        [CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_LAST_NAME]: '',
        [CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME]: '',
        [CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME]: '',
        // fillable start
        [CLAIMANT_REVIEW_FIELD_ALIASES.COACH_SIGNATURE]: '',
        [CLAIMANT_REVIEW_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL]: false,
        // fillable end
        [CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: null,
        [CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: null,
        [CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_DATE]: '',
        [CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_TIME]: '',
        [CLAIMANT_REVIEW_FIELD_ALIASES.INCIDENT_LOCATION]: '',
      },
    };
  },
  computed: {
    thankYouDialogContent() {
      if (this.fnolRejected) {
        return `<span class="fnol-review-view__modal-text">
                  Thank you
                </span>
                <br />
                <span class="fnol-review-view__modal-text">
                  You have declined a FNOL
                </span>`;
      }
      return `<span class="fnol-review-view__modal-text">
                Thank you
              </span>
              <br />
              <span class="fnol-review-view__modal-text">
                You have signed a FNOL
              </span>`;
    },
  },
  methods: {
    ...mapActions('analytics', ['logEvent']),
    ...mapActions('claims', ['fetchClaimForReviewByToken', 'confirmFnol', 'coachDecline']),
    handleClaimsInfoAgree() {
      this.form[CLAIMANT_REVIEW_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL] = true;
      this.hideClaimsInfoDisclaimer();
    },
    handleThankYouDialogClosed() {
      this.$router.push({
        name: 'infoScreen',
        params: {
          INFO_SCREEN_TYPE: INFO_SCREEN_TYPES_PARAMS[INFO_SCREEN_TYPES.THANK_YOU],
          TYPE: THANK_YOU_TYPES_PARAMS[THANK_YOU_TYPES.COACH_SUBMIT],
          CLAIMANT_NAME: `${this.form[CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_FIRST_NAME]} ${
            this.form[CLAIMANT_REVIEW_FIELD_ALIASES.CLAIMANT_LAST_NAME]
          }`,
        },
      });
    },
    showClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = true;
    },
    hideClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = false;
    },
    toggleRejectionDialog(payload = !this.rejectionDialogShown) {
      this.rejectionDialogShown = payload;
    },
    toggleThankYouDialog(payload = !this.thankYouDialogShown) {
      this.thankYouDialogShown = payload;
    },
    toggleLoadingForm(payload = !this.formIsLoading) {
      this.formIsLoading = payload;
    },
    async confirmFnolHandler(form = this.form) {
      this.toggleLoadingForm(true);
      const { slug: token } = this.$route.params;
      try {
        await this.confirmFnol({
          token,
          name: form[CLAIMANT_REVIEW_FIELD_ALIASES.COACH_SIGNATURE],
        });
        this.toggleThankYouDialog(true);
      } catch (error) {
        this.$dialog.error({ error });
      }

      this.toggleLoadingForm(false);
    },
    async rejectFnolHandler(reason, form = this.form) {
      this.toggleLoadingForm(true);
      const { slug: token } = this.$route.params;
      try {
        await this.coachDecline({
          token,
          reason,
          name: form[CLAIMANT_REVIEW_FIELD_ALIASES.COACH_SIGNATURE],
        });
        this.setFnolRejected(true);
        this.toggleThankYouDialog(true);
      } catch (error) {
        this.$dialog.error({ error });
      }
      this.toggleLoadingForm(false);
    },
    async prepareClaimForReview() {
      try {
        const { slug } = this.$route.params;
        const claimData = await this.fetchClaimForReviewByToken(slug);
        this.populateClaimParamsFromOrganizationImmediatly(claimData.sportsUnion);
        Object.assign(this.form, getClaimFormFromApiPayload(claimData));
        return true;
      } catch (error) {
        await this.$dialog.error({ error });
        this.$router.push({ name: 'notFound' });
        return false;
      }
    },
    async initReviewForm() {
      await this.prepareClaimForReview();
      this.logEvent({
        event: ANALYTIC_EVENTS.COACH_OPEN_FNOL,
        data: this.form.id,
      });
    },
    setFnolRejected(rejected = false) {
      this.fnolRejected = !!rejected;
    },
  },
  mounted() {
    this.initReviewForm();
  },
};
</script>

<style lang="scss" scoped>
.fnol-review-view {
  background-color: $background-lightest;
}
.fnol-review-view__container {
  max-width: 780px;
}
.fnol-review-view__header {
  padding-top: 90px;
}
.fnol-review-view__header-logo-wrapper {
  display: flex;
  margin-bottom: 40px;
}
.fnol-review-view__header-text-wrapper {
  margin-top: 12px;
}
.fnol-review-view__header-text {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
}
.fnol-review-view__modal-text-wrapper {
  margin-bottom: 74px;
  text-align: center;
}
.fnol-review-view__modal-text {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 38px;
  text-align: center;
  // letter-spacing: -0.06px;
  color: #000000;
}
</style>
